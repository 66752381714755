import React, { useState } from 'react';

import ReactPlayer from 'react-player';
import Button from '../Button';
import {
  Container, ContainerVideo, CloseButton,
} from './styledComponent';

function Video({ url, buttonTitle }) {
  const [isPlaying, setIsPlaying] = useState(
    false
  );

  function handleShow() {
    setIsPlaying(true);
    document.body.style.overflow = 'hidden';
  }

  function handleHide() {
    setIsPlaying(false);
    document.body.style.overflow = 'initial';
  }

  return (
    <Container>
      <Button onClick={handleShow} className="videoBtn">{buttonTitle}</Button>
      { isPlaying && (
      <ContainerVideo>
        <ReactPlayer
          url={url}
          controls="true"
          className="video-block"
          playing="true"
          width="100%"
          height="100%"
        />
        <CloseButton onClick={handleHide}>Close</CloseButton>
      </ContainerVideo>
      )}
    </Container>
  );
}

export default Video;
