import PropTypes from 'prop-types';
import React from 'react';

import Button from '../Button';
import {
  Container,
  ButtonContainer,
} from './styledComponent';


const CenteredParagraph = ({
  title, children, horizontalLayout, buttons,
}) => (
  <Container horizontalLayout={horizontalLayout}>
    <h2 dangerouslySetInnerHTML={{
      __html: title,
    }}
    />
    <section>
      {children}
      {buttons && (
      <ButtonContainer>
        {buttons.map((button, i) => {
          const { title: buttonTitle, href } = button;
          return (
            <Button href={href} key={`${buttonTitle + i}`}>{buttonTitle}</Button>
          );
        })}
      </ButtonContainer>
      )}
    </section>
  </Container>
);

CenteredParagraph.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  })).isRequired,

  horizontalLayout: PropTypes.bool,
};


export default CenteredParagraph;
