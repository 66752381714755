import React from 'react';

// import config from '../config';

import LayersManager from 'stoomlink-commons-ui/LayersManager';
import ThemeProvider from 'stoomlink-commons-ui/ThemeProvider/ThemeProvider';
import Layout from '../layouts/Layout';
import Head from '../layouts/Head';

import TextAndPicture from '../components/TextAndPicture';
import Container from '../layouts/Container';
import FocusContainer from '../layouts/FocusContainer';
import FloatingParagraph from '../components/FloatingParagraph';
import CenteredParagraph from '../components/CenteredParagraph';
import ResponsiveImage from '../components/ResponsiveImage';

import Button from '../components/Button';
import Card from '../components/Card';
import LogoWall from '../components/LogoWall';
import List from '../components/List';
import ActionCard from '../components/ActionCard';

import Hero from '../components/Hero';
import Quote from '../components/Quote';
import Footer from '../components/Footer';

import MailSection from '../components/MailSection';

import MockupSD from '../assets/images/products/mockups/smartdisplays.png';
import MockupBroadcast from '../assets/images/products/mockups/broadcast-1.png';
import MockupMobiscore from '../assets/images/products/mockups/mobiscore-desktop-1.png';

import PhotoSD from '../assets/images/products/shots/smartdisplays-street-1.jpg';

import TeamMember from '../components/TeamMember';
import team from '../../content/team';
import shuffleArray from '../utils/shuffleArray';

import Video from '../components/Video';


// =============================================================================
// Content
// =============================================================================

const pageTitle = 'StyleGuide';

const lipsum = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus, lacus id bibendum mattis, metus ante maximus ex, dictum viverra ex nisl luctus ante. Proin pharetra vel tellus nec sodales.';
const href = '/';

const heroTitle = 'Mandatory <span class="focus-accent">huge</span> title,<br>with some <span class="focus-main">emphasis</span>.';
const heroContent = `Meaningless hero content no one will read can spread on a few lines. ${lipsum}`;

const textAndPicturesItems = [
  {
    title: 'Some Text. Some <span class="focus-accent">Pictures</span>',
    content: `Still more content no one cares about. ${lipsum}`,
    buttons: [{ title: 'Buttons to make you click', href: '/', note: 'And tiny notes below them.' }],
    src: MockupMobiscore,
  },

  {
    title: 'We alternate left and <span class="focus-accent">right</span>.<br/>',
    content: `And buttons are optional. By the way we are perfectly aware this page is freely available. Don't bother mentionning us on Twitter or you'll owe us a drink. ${lipsum}`,
    src: PhotoSD,
  },
];

const focusTitle = 'We have flashy <br><span class="focus-light">sections</span> like every other startups.';
const focusContent = `
<p>I'm some short content.<br/>To emphasize something.</p>
<p>It's very effective.</p>
<p>People don't read longer text</br>Anyway.</p>
<p>We don't really care.`;
const focusButton = 'As long as they buy our stuff';


const secondFocusTitle = 'Sometime we\'re afraid of not being heavy enough.';
const secondFocusContent = `
<p>So we start writing kilometers of content.</p>
<p>${lipsum}</p>
<p>${lipsum}</p>
<p>${lipsum}</p>`;

const secondFocusButtonTitle = 'And hope it will make you buy stuff';

const quoteBreak = 'We use a big meaningless<br/><span class="focus-main">quote</span> to split content.';
const listBreak = 'We also have more <br/><span class="focus-main">clickable</span> list.';

const list = [
  {
    title: 'This is a <span class="focus-accent">list</span>',
    content: `${lipsum}`,
  },

  {
    title: 'List can have <span class="focus-accent">buttons</span>',
    content: `${lipsum}`,
    buttons: [
      { title: 'Buttons are optional', href: '/' },
      { title: 'You can have multiple', href: '/' },
    ],
  },

  {
    title: 'You know what would be <span class="focus-accent">revolutionnary?</span>',
    content: '<p>A list that is not always 3 items long.</p><p>Still, be consistant when using one.If one list item has a button, all should.<br/>Unlike this one. This is ugly.</p><p>Try to have almost same length of text in every item of the list.</p>',
  },
];

const cardTitle = 'We have pretty <span class="focus-accent">cards</span>';
const cardContent = `They exist in 3 variants. ${lipsum}`;
const cardButtonTitle = 'Card can have buttons';

const quote = 'We have also have bold <br><span class="focus-accent">startupish quotes</span> to end pages.';

const ctaTitle = 'We end with some bold call to action';
const ctaContent = `With anything, just anything to make you visit another page. ${lipsum}`;
const ctaLinkTitle = 'For the love of god, click me';

const videoLinks = [
  {
    btnTitle: 'Watch a Youtube video',
    url: 'https://www.youtube.com/watch?v=ysz5S6PUM-U',
  },
  {
    btnTitle: 'Watch a Facebook video',
    url: 'https://www.facebook.com/facebook/videos/10153231379946729/',
  },
  {
    btnTitle: 'Watch a Vimeo video',
    url: 'https://vimeo.com/169599296',
  },
  {
    btnTitle: 'Watch a twitch video',
    url: 'https://www.twitch.tv/videos/106400740',
  },
  {
    btnTitle: 'Watch a MP4 video',
    url: 'https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4',
  },
];


// =============================================================================
// Page
// =============================================================================


const StyleGuidePage = () => (
  <ThemeProvider themeStyle="light">
    <LayersManager>
      <Layout>
        <Head title={pageTitle} />
        <Hero title={heroTitle}>
          <p>{heroContent}</p>
        </Hero>

        <Container>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            {shuffleArray(Object.keys(team.members)).map((el, i) => {
              return (
                <TeamMember
                  key={`${el + i}`}
                  member={el}
                />
              );
            })}
          </div>
        </Container>

        {textAndPicturesItems.map((item, index, i) => {
          const layout = (index % 2 === 0) ? 'odd' : 'even';
          const {
            title, content, src, buttons, parralax,
          } = item;
          return (
            <TextAndPicture src={src} parallax={parralax} layout={layout} key={`${title + i}`}>
              <FloatingParagraph
                title={title}
                buttons={buttons}
              >
                {content}
              </FloatingParagraph>
            </TextAndPicture>
          );
        })}
        <Container>
          <MailSection />
          <Quote layout="center">{quoteBreak}</Quote>
          <List items={list} />

          <Quote layout="center">{listBreak}</Quote>
          <List items={list} theme="secondary" />

          <Card>
            <h2 dangerouslySetInnerHTML={{
              __html: cardTitle,
            }}
            />
            <p>{cardContent}</p>
            <Button href={href}>{cardButtonTitle}</Button>
          </Card>

          <Card theme="secondary">
            <h2 dangerouslySetInnerHTML={{
              __html: cardTitle,
            }}
            />
            <p>{cardContent}</p>
            <Button href={href}>{cardButtonTitle}</Button>
          </Card>

          <Card theme="accent">
            <h2 dangerouslySetInnerHTML={{
              __html: cardTitle,
            }}
            />
            <p>{cardContent}</p>
            <Button href={href} theme="secondary">{cardButtonTitle}</Button>
          </Card>

        </Container>

        <Container>
          <Quote>{quoteBreak}</Quote>
          <LogoWall title="Brands that never heard of us" />
        </Container>

        <FocusContainer>
          <TextAndPicture src={MockupBroadcast} parallax={[0, 0]}>
            <FloatingParagraph
              title={focusTitle}
              buttonTitle={focusButton}
              buttons={[{ title: focusButton, href }]}
            >
              {focusContent}
            </FloatingParagraph>
          </TextAndPicture>
          <LogoWall title="Showing you the same brands again, to be sure" />
        </FocusContainer>

        <Container>
          <Quote>{quoteBreak}</Quote>
          <ResponsiveImage src={MockupSD} hasParallax={false} />
        </Container>
        <Container>
          <LogoWall title="It's some kind of obsession" />
        </Container>

        <FocusContainer>
          <Container>
            <CenteredParagraph
              title={secondFocusTitle}
              buttonTitle={secondFocusButtonTitle}
              buttons={[{ title: secondFocusTitle, href }]}
            >
              <div dangerouslySetInnerHTML={{
                __html: secondFocusContent,
              }}
              />
            </CenteredParagraph>
          </Container>
        </FocusContainer>

        <Container>
          <Quote>{quote}</Quote>
          <LogoWall title="It's some kind of obsession" />
        </Container>

        <Container>
          {videoLinks.map((item, i) => {
            const {
              btnTitle, url,
            } = item;
            return (
              <Video
                key={i.toString()}
                buttonTitle={btnTitle}
                url={url}
              />
            );
          })}
        </Container>

        <ActionCard
          title={ctaTitle}
          linkTitle={ctaLinkTitle}
          buttons={[{ title: ctaLinkTitle, href: '#intercom' }]}
        >
          {ctaContent}
        </ActionCard>

        <Footer />
      </Layout>
    </LayersManager>
  </ThemeProvider>
);

export default StyleGuidePage;
