import styled from 'styled-components';

export const Container = styled.div`
  margin: 64px 0;
`;

export const ContainerVideo = styled.div`
  position: fixed;
  z-index: 20000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;

  .video-block {
    padding: 64px;
    box-sizing: border-box;
    max-width: 100%;
    max-height: 100%;

    video {
      outline: 0;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }

    @media screen and (max-width: 667px){
      padding: 60% 18px;
    }
    @media screen and (max-width: 980px){
      padding: 35% 18px;
    }
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  display: block;
  outline: none;
  z-index: 30000;
  background: none;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  border: none;
  color: transparent;

  &:hover {
    cursor: pointer;
  }

  &::before {
    transform: translateY(-50%) rotate(45deg);
  }
  &::after {
    transform: translateY(-50%) rotate(-45deg);
  }
  &::before, &::after {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background: #000000;
    position: absolute;
    top: 50%;
    -webkit-transition: all .4s cubic-bezier(.3,1,.3,1);
    transition: all .4s cubic-bezier(.3,1,.3,1);
  }
`;
