import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { colors } from '../../theme/index';

const ContainerStyle = styled.div`
  background-color: ${colors.accent};
  padding: 64px;
`;

const FocusContainer = ({ /* title,  */children }) => (
  <ContainerStyle>
    {children}
  </ContainerStyle>
);
FocusContainer.propTypes = {
  children: PropTypes.node,
};

export default FocusContainer;
